import { Navigate, Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AuthPage from 'components/pages/auth/AuthPage';
import LoginForm from 'components/pages/auth/login/LoginForm';
import CheckEmailForRegisterText from 'components/pages/auth/register/confirmation/CheckEmailText';
import SuccessfulConfirmation from 'components/pages/auth/register/confirmation/SuccessfulConfirmation';
import RegisterForm from 'components/pages/auth/register/RegisterForm';
import ChangePasswordForm from 'components/pages/auth/resetPassword/changePasswordForm/ChangePasswordForm';
import CheckEmailForResetText from 'components/pages/auth/resetPassword/confirmation/CheckEmailText';
import ResetPasswordForm from 'components/pages/auth/resetPassword/ResetPasswordForm';
// TODO: TAS-1007
// import AccountantPage from 'components/pages/balance/accountant/AccountantPage';
// import BalancePage from 'components/pages/balance/BalancePage';
// import ReceiptForm from 'components/pages/balance/receiptForm/ReceiptForm';
// import ReceiptResult from 'components/pages/balance/receiptResult/ReceiptResult';
// import TopUpCard from 'components/pages/balance/topUpWithCard/TopUpWithCard';
// import ProfileReceipts from 'components/pages/profile/receipts/ProfileReceipts';
import MainPage from 'components/pages/main/MainPage';
import ProfilePassword from 'components/pages/profile/change-password/ProfilePassword';
import ProfileInfo from 'components/pages/profile/info/ProfileInfo';
import ProfilePage from 'components/pages/profile/ProfilePage';
import ProfileSettings from 'components/pages/profile/settings/ProfileSettings';
import ArchivePage from 'components/pages/records/archive/ArchivePage';
import TemplatePageLayout from 'components/pages/records/TemplatePage';
import TranscriptPage from 'components/pages/records/transcript/TranscriptPage';
import NonExistentPage from 'components/pages/service/NonExistentPage';
import NotCreatedPage from 'components/pages/service/NotCreatedPage';
import ShareRedirection from 'components/pages/shareRedirection/ShareRedirection';
import SpeakersPage from 'components/pages/speakers/SpeakersPage';

import Dictionary from 'components/views/dictionary/dictionary/Dictionary';
import DictionaryList from 'components/views/dictionary/DictionaryList';
import UploadingPage from 'components/views/recordUploader/UploadingPage';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

function App() {
    return (
        <Router>
            <div className='App'>
                <ToastContainer autoClose={1500} />
                <Routes>
                    <Route path='' element={<Navigate replace to='/archive' />} />
                    <Route path='/*' element={<MainPage />} />
                    <Route path='login' element={<AuthPage content={<LoginForm />} />} />
                    <Route path='confirm' element={<AuthPage content={<CheckEmailForRegisterText />} />} />
                    <Route path='confirm/:token' element={<AuthPage content={<SuccessfulConfirmation />} />} />
                    <Route path='register' element={<AuthPage content={<RegisterForm />} />} />
                    <Route path='reset-password' element={<AuthPage content={<ResetPasswordForm />} />} />
                    <Route path='reset-password/confirm' element={<AuthPage content={<CheckEmailForResetText />} />} />
                    <Route path='password_change/:token' element={<AuthPage content={<ChangePasswordForm />} />} />

                    <Route path='share/transcript/:token' element={<ShareRedirection />} />

                    <Route path='/*' element={<TemplatePageLayout />}>
                        {/* TODO: TAS-1007 */}
                        {/* <Route path='balance' element={<BalancePage />}>
                            <Route path='receipt' element={<ReceiptForm />} />
                            <Route path='receipt/result' element={<ReceiptResult />} />
                            <Route path='top-up-card' element={<TopUpCard />} />
                        </Route>
                        <Route path='accountant' element={<AccountantPage />} /> */}
                        <Route path='uploading' element={<UploadingPage />} />
                        <Route path='archive' element={<ArchivePage />} />
                        <Route path='speakers' element={<SpeakersPage />} />
                        <Route path='support' element={<NotCreatedPage />} />
                        <Route path='manual' element={<NotCreatedPage />} />
                        <Route path='archive/transcript/:transcriptId' element={<TranscriptPage />} />
                        <Route path='dict/' element={<DictionaryList />} />
                        <Route path='dict/:dictId' element={<Dictionary />} />
                        <Route path='profile' element={<ProfilePage />}>
                            <Route path='info' element={<ProfileInfo />} />
                            <Route path='info/change-password' element={<ProfilePassword />} />
                            <Route path='settings' element={<ProfileSettings />} />
                            {/* TODO: TAS-1007 */}
                            {/* <Route path='receipts' element={<ProfileReceipts />} /> */}
                        </Route>
                        <Route path='*' element={<NonExistentPage />} />
                    </Route>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
