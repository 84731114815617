import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useGetFilesQuery } from 'api/routes/RecordsApi';
import { useEditResultMutation } from 'api/routes/ResultsApi';
import { TRANSCRIPT_NAME_LENGTH_MAX_LIMIT } from 'constants/common';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setSaved, setTranscriptName } from 'store/reducers/transcriptions/transcriptSlice';

import './TranscriptName.scss';

const TranscriptName: React.FC = () => {
    const dispatch = useAppDispatch();
    const [sendEditResult] = useEditResultMutation();

    const { transcript, editedTranscript } = useAppSelector((state) => state.transcript);
    const { shareAccessToken, isEditingAllowed } = useAppSelector((state) => state.transcriptAccess);
    const { data: files } = useGetFilesQuery(null);

    let { transcriptId } = useParams();

    const getRecordName = useCallback(() => {
        const currentFile = files?.results?.find((el) => el.file_id === transcript?.file_id);
        return currentFile ? currentFile.path[0] : '';
    }, [files?.results, transcript?.file_id]);

    const currentTranscriptName = useMemo(
        () =>
            (editedTranscript?.user_path && editedTranscript?.user_path[editedTranscript?.user_path.length - 1]) ||
            getRecordName(),
        [editedTranscript?.user_path, getRecordName],
    );

    const handleChangeName = async (e: React.FocusEvent<HTMLDivElement>) => {
        if (e.currentTarget.textContent) {
            if (e.currentTarget.textContent === currentTranscriptName) {
                return;
            }

            dispatch(setSaved({ isSaved: false, timestamp: Date.now() }));
            dispatch(setTranscriptName({ name: e.currentTarget.textContent }));
            const name = structuredClone(editedTranscript.user_path);
            name[name.length - 1] = e.currentTarget.textContent;
            await sendEditResult({
                req_id: transcriptId || '',
                result: { user_path: name },
                shareAccessToken,
            })
                .unwrap()
                .catch((error) => {
                    if (error.status === 406) {
                        toast.error('Данное название уже занято');
                    }
                });
            dispatch(setSaved({ isSaved: true, timestamp: Date.now() }));
        }
    };

    const isServeKey = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
        return (
            event.ctrlKey ||
            event.code === 'Enter' ||
            event.code === 'Backspace' ||
            event.code === 'Delete' ||
            event.code === 'ArrowLeft' ||
            event.code === 'ArrowUp' ||
            event.code === 'ArrowRight' ||
            event.code === 'ArrowDown'
        );
    }, []);

    return (
        <div
            className={`transcript-name ${isEditingAllowed ? 'transcript-name--editable' : ''}`}
            contentEditable={isEditingAllowed}
            suppressContentEditableWarning
            onBlur={(e) => handleChangeName(e)}
            onKeyDown={(e) => {
                if (e.currentTarget?.textContent && e.currentTarget.textContent.length >= 50 && !isServeKey(e)) {
                    e.preventDefault();
                }

                if (e.code === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    e.target.blur();
                }
            }}
            onPaste={(e) => {
                e.preventDefault();

                const offsetPosition = document.getSelection()?.getRangeAt(0)?.startOffset || 0;
                const title = e.currentTarget.textContent || '';
                const text = e.clipboardData
                    .getData('text/plain')
                    .replace(/\s+/g, ' ')
                    .trim()
                    .slice(0, TRANSCRIPT_NAME_LENGTH_MAX_LIMIT - title.length);

                e.currentTarget.textContent = title.slice(0, offsetPosition) + text + title.slice(offsetPosition);

                const nodePosition = document.getSelection()?.getRangeAt(0)?.startContainer.childNodes[0];
                if (nodePosition) {
                    const s = document.getSelection();
                    const range = document.createRange();
                    range.setStart(nodePosition, offsetPosition + text.length);
                    s?.removeAllRanges();
                    s?.addRange(range);
                }
            }}
        >
            {currentTranscriptName}
        </div>
    );
};

export default TranscriptName;
