import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from 'api/query';
import { RecognizePayload, RecognizeResponse, RequestsResponse } from 'api/types/controllers';

const API_API_HOST = process.env.REACT_APP_API_API_HOST;

export const requestsApi = createApi({
    reducerPath: 'requestsApi',
    tagTypes: ['Requests'],
    baseQuery: customBaseQuery({ baseUrl: `${API_API_HOST}/api/` }),
    endpoints: (build) => {
        return {
            createProtocol: build.mutation<{ request_id: string; task_id: string }, string>({
                query: (req_id) => ({
                    url: `summarize/${req_id}/`,
                    method: 'GET',
                }),
                invalidatesTags: [{ type: 'Requests' }],
            }),
            requests: build.query<RequestsResponse, null>({
                query: () => ({
                    url: `requests/`,
                    method: 'GET',
                }),
                providesTags: [{ type: 'Requests' }],
            }),
            revokeRequest: build.mutation<boolean, { taskId: string }>({
                query: ({ taskId }) => ({
                    url: `task/${taskId}/revoke/`,
                    method: 'GET',
                }),
                invalidatesTags: [{ type: 'Requests' }],
            }),
            recognizeFile: build.mutation<RecognizeResponse, RecognizePayload>({
                query: (fileInfo) => ({
                    url: `recognize/${fileInfo.fileId}/`,
                    method: 'POST',
                    body: fileInfo,
                }),
                invalidatesTags: [{ type: 'Requests' }],
            }),
        };
    },
});

export const {
    useRecognizeFileMutation,
    useRequestsQuery,
    useLazyRequestsQuery,
    useRevokeRequestMutation,
    useCreateProtocolMutation,
} = requestsApi;
