import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import { Bars } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';

import { FileErrorPayload, RecordFileUploaderProps } from '../UploadingPage.interface';

import { useDeleteFileMutation, useUploadFileMutation } from 'api/routes/RecordsApi';
import { ReactComponent as SuccessIcon } from 'assets/check-mark-in-circle.svg';
import { ReactComponent as ErrorIcon } from 'assets/cross-in-circle.svg';
import { ReactComponent as CrossIcon } from 'assets/cross.svg';
import { LS } from 'config/env';
import { ALLOWED_EXTENSIONS, ALLOWED_TYPES, FILE_ERROR, UPLOADING_ERROR_MAP } from 'constants/common';
import { UPLOADING_FILE_ERROR_MESSAGES } from 'constants/messages';
import { useAppSelector } from 'hooks/useAppSelector';
import {
    setActiveUploadName,
    resetState,
    setActiveUploadRequest,
    cancelActiveUpload,
} from 'store/reducers/file/fileSlice';

import './RecordFileUploader.scss';
const maxNumOfUploadedFiles = 5;

const RecordFileUploader: React.FC<RecordFileUploaderProps> = ({
    navigateToNextStep,
    uploadedFiles,
    addFileToUploaded,
    handleCancelUpload,
    removeUploadedFileById,
}): JSX.Element => {
    const [spinnerIsActive, setSpinnerIsActive] = useState<boolean>(false);
    const [failedFile, setFailedFile] = useState<FileErrorPayload | null>(null);
    const uploaderInputRef = useRef<HTMLInputElement>(null);
    const [uploadFile] = useUploadFileMutation();
    const [deleteFile] = useDeleteFileMutation();
    const dispatch = useDispatch();
    const { activeUpload } = useAppSelector((state) => state.file);

    useEffect(() => {
        if (uploadedFiles) {
            localStorage.setItem(LS.FILES, JSON.stringify(uploadedFiles));
        }
    }, [uploadedFiles]);

    useEffect(() => {
        if (activeUpload) {
            setSpinnerIsActive(activeUpload.loading);
            if (activeUpload.fulfilled) {
                addFileToUploaded(activeUpload.fileInfo);
                dispatch(resetState());
            }
            if (activeUpload.rejected && !activeUpload.canceled) {
                setFailedFile({ name: activeUpload.fileInfo.name, error: FILE_ERROR.UPLOADING });
                dispatch(resetState());
            }
        }
    }, [activeUpload, addFileToUploaded, dispatch]);

    const checkFileType = useCallback((type: string): boolean => {
        return ALLOWED_TYPES.some((el) => type.startsWith(el));
    }, []);

    const uploadSelectedFile = useCallback(
        (file: File) => {
            const formData = new FormData();
            formData.append('file', file);
            dispatch(setActiveUploadName(file.name));

            const trigger = uploadFile(formData);
            trigger.unwrap().catch((e) => {
                setFailedFile({
                    name: file.name,
                    error: UPLOADING_ERROR_MAP[e?.data?.code] ?? FILE_ERROR.UPLOADING,
                });
            });
            dispatch(setActiveUploadRequest(trigger));
        },
        [uploadFile, dispatch],
    );

    const filesManage = useCallback(
        (files: FileList | null) => {
            if (uploadedFiles.length >= maxNumOfUploadedFiles || !files || files.length <= 0) {
                return;
            }

            const file = files[0];
            if (checkFileType(file.type)) {
                uploadSelectedFile(file);
            } else {
                setFailedFile({
                    name: file.name,
                    error: FILE_ERROR.TYPE,
                });
            }
        },
        [checkFileType, uploadSelectedFile, uploadedFiles.length],
    );

    const onFileInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            filesManage(event.target.files);
            event.target.value = '';
        },

        [filesManage],
    );

    const onFrameDragEnterHandler = useCallback(() => {
        setFailedFile(null);
    }, []);

    const onSelectManuallyClick = useCallback(() => {
        if (uploadedFiles.length < maxNumOfUploadedFiles) {
            uploaderInputRef?.current?.click();
        }
    }, [uploadedFiles.length]);

    const handleContinue = useCallback(() => {
        navigateToNextStep();
    }, [navigateToNextStep]);

    const handleDeleteFile = useCallback(
        (id: string) => {
            deleteFile(id)
                .unwrap()
                .finally(() => {
                    removeUploadedFileById(id);
                });
        },
        [deleteFile, removeUploadedFileById],
    );

    const checkIfNoSelectedFiles = useCallback((): boolean => {
        return !failedFile && uploadedFiles.length === 0;
    }, [failedFile, uploadedFiles.length]);

    const handleCancelActiveUploading = useCallback(() => {
        activeUpload.request?.abort();
        dispatch(cancelActiveUpload());
    }, [activeUpload.request, dispatch]);

    const getInfoAboutFailedFiles = useCallback((): string => {
        return `Файл ${failedFile?.name} не загружен, так как ${UPLOADING_FILE_ERROR_MESSAGES[failedFile?.error!]}`;
    }, [failedFile?.error, failedFile?.name]);

    const renderContainerBody = (): React.ReactNode => {
        if (spinnerIsActive) {
            return (
                <div className='active_uploading'>
                    <Bars
                        height='50'
                        width='50'
                        color='#814feb'
                        ariaLabel='bars-loading'
                        wrapperStyle={{}}
                        wrapperClass=''
                    />
                    <p className='name'>{activeUpload.fileInfo.name}</p>
                    <div className='cancel' onClick={handleCancelActiveUploading}>
                        Отменить
                    </div>
                </div>
            );
        } else if (checkIfNoSelectedFiles()) {
            return (
                <div className='text_container'>
                    <p className='actions_text'>
                        Перетащите файл в это окно или <br />
                        <span className='select_manually_text' onClick={onSelectManuallyClick}>
                            выберите вручную
                        </span>
                    </p>
                    <div className={classNames('requirements_text', { error: false })}>
                        Требования к файлу: до 60 минут, не более 200 МБ. <br />
                        Поддерживаются{' '}
                        <div className='tooltip'>
                            <span className='wrapped-text'> аудио и видео</span>
                            <div className='tooltip-text'>{ALLOWED_EXTENSIONS.join(', ')}</div>
                        </div>{' '}
                        форматы
                    </div>
                </div>
            );
        } else {
            return (
                <div className='uploading-process_container'>
                    {uploadedFiles?.map((el) => (
                        <div className='result_wrapper' key={el.id}>
                            <SuccessIcon className='icon' />
                            <p>Файл {el.name} успешно загружен</p>
                            <CrossIcon className='icon cancel' onClick={() => handleDeleteFile(el.id)} />
                        </div>
                    ))}
                    {failedFile && (
                        <div className='result_wrapper'>
                            <ErrorIcon className='icon error' />
                            <p>{getInfoAboutFailedFiles()}</p>
                        </div>
                    )}
                    {uploadedFiles.length < maxNumOfUploadedFiles && (
                        <p className='select_manually_text' onClick={onSelectManuallyClick}>
                            Добавить ещё один файл
                        </p>
                    )}
                    <div className='navigation-buttons_wrapper'>
                        <button
                            type='button'
                            className='btn btn_secondary'
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCancelActiveUploading();
                                handleCancelUpload();
                                setFailedFile(null);
                            }}
                        >
                            Отменить
                        </button>
                        <button
                            type='button'
                            className='btn btn_primary'
                            disabled={uploadedFiles.length === 0}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleContinue();
                            }}
                        >
                            Далее
                        </button>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className='uploader_wrapper'>
            <form className='uploader_form'>
                <div className='drag_drop_area'>
                    <FileDrop
                        onFrameDragEnter={onFrameDragEnterHandler}
                        onDrop={filesManage}
                        draggingOverTargetClassName={classNames('file-drop-dragging-over-target', {
                            disabled: uploadedFiles.length >= maxNumOfUploadedFiles,
                        })}
                    >
                        {renderContainerBody()}
                    </FileDrop>
                    <input
                        accept={ALLOWED_TYPES.map((type) => `${type}/*`).join(', ')}
                        onChange={onFileInputChange}
                        ref={uploaderInputRef}
                        type='file'
                        className='hidden'
                    />
                </div>
            </form>
        </div>
    );
};

export default RecordFileUploader;
