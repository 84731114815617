import { useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useLazyResultQuery } from 'api/routes/ResultsApi';
import { ExchangeShareTokenReponse } from 'api/types/controllers';
import SectionsLayout from 'components/pages/records/layout/SectionsLayout';
import NewTranscriptControl from 'components/pages/records/transcript/newControl/NewTranscriptControl';
import DemoProcessing from 'components/views/demoProcessing/DemoProcessing';
import TranscriptList from 'components/views/transcriptionsList/TranscriptList';
import { SHARE_GRANT, SHARE_GRANT_LABEL } from 'constants/common';
import { getShareAccessInfo } from 'helpers/LocalStorageActions';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { audioReset } from 'store/reducers/transcriptions/audioSlice';
import {
    transcriptAccessReset,
    setIsEditingAllowed,
    setShareAccessToken,
} from 'store/reducers/transcriptions/transcriptAccess';
import { transcriptAppearanceReset } from 'store/reducers/transcriptions/transcriptAppearanceSlice';
import { reset } from 'store/reducers/transcriptions/transcriptSlice';

import './TranscriptPage.scss';

const TranscriptPage: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { transcriptId } = useParams();

    const [getTranscript, { isError: isTranscriptError }] = useLazyResultQuery();

    const { transcript } = useAppSelector((state) => state.transcript);

    useEffect(() => {
        if (!transcriptId) {
            return;
        }

        getTranscript({ transcriptId })
            .unwrap()
            .catch((error) => {
                if (error.status === 403) {
                    const sharedInfo = getShareAccessInfo();
                    let shareAccessToken;
                    let shareRole: SHARE_GRANT;

                    if (sharedInfo) {
                        const targetTranscript = JSON.parse(sharedInfo).find(
                            (item: ExchangeShareTokenReponse) => item.object === transcriptId,
                        );

                        if (targetTranscript) {
                            shareAccessToken = targetTranscript.auth;
                            shareRole = targetTranscript.grant;
                        }
                    }

                    if (!shareAccessToken) {
                        toast.error('У вас нет прав на просмотр данной стенограммы', {
                            onClose: () => navigate('/archive'),
                        });
                        return;
                    }

                    dispatch(setShareAccessToken(shareAccessToken));
                    getTranscript({ transcriptId, shareAccessToken })
                        .unwrap()
                        .then(() => {
                            if (shareRole !== undefined) {
                                dispatch(setIsEditingAllowed(shareRole === SHARE_GRANT.Edit));
                                toast.success(
                                    `Вам предоставлены права на ${SHARE_GRANT_LABEL[shareRole].toLocaleLowerCase()}`,
                                );
                            }
                        })
                        .catch((error) => {
                            if (error.status === 403) {
                                toast.error('У вас нет прав на просмотр данной стенограммы', {
                                    onClose: () => navigate('/archive'),
                                });
                            }
                        });
                } else {
                    toast.error('Что-то пошло не так', { onClose: () => navigate('/archive') });
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcriptId]);

    useEffect(() => {
        return () => {
            dispatch(reset());
            dispatch(transcriptAccessReset());
            dispatch(audioReset());
            dispatch(transcriptAppearanceReset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {Object.keys(transcript).length ? (
                <>
                    <NewTranscriptControl />
                    <div className='transcript_page'>
                        <SectionsLayout
                            firstChildren={<TranscriptList />}
                            secondChildren={<DemoProcessing />}
                            control={<></>}
                            header={<></>}
                        />
                    </div>
                </>
            ) : (
                <div className='transcript-loading-wrapper'>
                    <RotatingLines
                        strokeColor='#814feb'
                        strokeWidth='5'
                        animationDuration='0.75'
                        width='40'
                        visible={!isTranscriptError}
                    />
                    {isTranscriptError && <button className='btn btn_primary'>К архиву</button>}
                </div>
            )}
        </>
    );
};

export default TranscriptPage;
