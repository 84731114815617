import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import TranscriptControlMenu from './controlMenu/TranscriptControlMenu';
import TranscriptName from './transcriptName/TranscriptName';

import { useLazyGetFileMetadataQuery } from 'api/routes/RecordsApi';
import { ReactComponent as ArrowIcon } from 'assets/arrow-without-body.svg';
import { ReactComponent as CloseIcon } from 'assets/cross.svg';
// import { ReactComponent as CleanIcon } from 'assets/transcript/clean.svg';
import { ReactComponent as DecreaseFont } from 'assets/transcript/decrease-font.svg';
import { ReactComponent as HighlightIcon } from 'assets/transcript/highlight.svg';
import { ReactComponent as IncreaseFont } from 'assets/transcript/increase-font.svg';
// import { ReactComponent as InexactIcon } from 'assets/transcript/inexact.svg';
import { ReactComponent as LogoIcon } from 'assets/transcript/logo.svg';
import { ReactComponent as ProjectsIcon } from 'assets/transcript/projects.svg';
import { ReactComponent as ProtocolIcon } from 'assets/transcript/protocol-list.svg';
import { ReactComponent as QuestionIcon } from 'assets/transcript/question.svg';
import { ReactComponent as RedoIcon } from 'assets/transcript/redo.svg';
import { ReactComponent as TaskIcon } from 'assets/transcript/task.svg';
import { ReactComponent as UndoIcon } from 'assets/transcript/undo.svg';
import SimpleTranscriptShare from 'components/pages/records/transcript/newControl/transcriptShare/SimpleTranscriptShare';
import TranscriptPlayer from 'components/views/transcriptionsList/transcriptPlayer/TranscriptPlayer';
import { getDateFromTimestamp, getTimeFromTimestamp } from 'helpers/TimeFormatting';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import {
    changeDemoView,
    decreaseFontSize,
    increaseFontSize,
} from 'store/reducers/transcriptions/transcriptAppearanceSlice';
import { setNextVersion, setPreviousVersion } from 'store/reducers/transcriptions/transcriptSlice';
import { FileType } from 'types/models';

import './NewTranscriptControl.scss';

const NewTranscriptControl: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [getFileInfo] = useLazyGetFileMetadataQuery();

    const { currentVersion, versionChanges, isSaved, savingTimestamp, transcript } = useAppSelector(
        (state) => state.transcript,
    );
    const { demoView } = useAppSelector((state) => state.transcriptAppearance);
    const { isEditingAllowed, shareAccessToken } = useAppSelector((state) => state.transcriptAccess);
    const currentRecord = transcript;

    const [audioInfo, setAudioInfo] = useState<FileType>();
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
    const [shareToken, setShareToken] = useState<string | null>(null);

    useEffect(() => {
        if (!currentRecord?.file_id) {
            return;
        }

        getFileInfo({ fileId: currentRecord?.file_id, shareAccessToken })
            .unwrap()
            .then((info) => setAudioInfo(info));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRecord?.file_id]);

    const scrollToTop = useCallback(() => {
        const transcriptPage = document.querySelector('.section_left');
        if (transcriptPage) {
            transcriptPage.scrollTop = 0;
        }
    }, []);

    return (
        <>
            {!!Object.keys(currentRecord).length && (
                <div className='transcript-control'>
                    <div className='transcript-control__top'>
                        <LogoIcon className='transcript-control__logo' onClick={() => navigate('/uploading')} />
                        <div className='transcript-control__content'>
                            <div>
                                <div className='transcript-control__name-wrapper'>
                                    <TranscriptName />
                                    {!isEditingAllowed && (
                                        <div className='transcript-control__readonly-label'>Только чтение</div>
                                    )}
                                </div>
                                <span className='transcript-control__upload-date'>
                                    {`Загружено ${getDateFromTimestamp(
                                        currentRecord.timestamp,
                                    )} в ${getTimeFromTimestamp(currentRecord.timestamp)}`}
                                </span>
                                <button
                                    className='btn btn_transparent transcript-control__share'
                                    onClick={(e) => {
                                        if (!isEditingAllowed) {
                                            return;
                                        }
                                        setShareModalIsOpen(true);
                                    }}
                                >
                                    Доступ
                                </button>
                            </div>
                            <div>
                                <TranscriptControlMenu />
                                <TranscriptPlayer
                                    audioUrl={audioInfo?.stream_url || ''}
                                    duration={audioInfo?.duration || 0}
                                />
                            </div>
                        </div>
                        <div
                            className='transcript-control__close'
                            onClick={() => {
                                navigate('/archive');
                            }}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                    <div className='transcript-control__bottom'>
                        <div className='transcript-control__actions'>
                            <div className='transcript-control__actions-group'>
                                <div
                                    className={`btn btn_with_icon ${currentVersion === 0 && 'btn-disabled'}`}
                                    onClick={() => {
                                        dispatch(setPreviousVersion());
                                    }}
                                >
                                    <UndoIcon />
                                </div>
                                <div
                                    className={`btn btn_with_icon ${
                                        currentVersion === versionChanges.length - 1 && 'btn-disabled'
                                    }`}
                                    onClick={() => {
                                        dispatch(setNextVersion());
                                    }}
                                >
                                    <RedoIcon />
                                </div>
                            </div>
                            <div className='transcript-control__actions-group'>
                                <div
                                    className='btn btn_with_icon'
                                    onClick={() => {
                                        dispatch(increaseFontSize());
                                    }}
                                >
                                    <IncreaseFont />
                                </div>
                                <div
                                    className='btn btn_with_icon'
                                    onClick={() => {
                                        dispatch(decreaseFontSize());
                                    }}
                                >
                                    <DecreaseFont />
                                </div>
                            </div>
                            {/* закомментировано до появления соответствующего функционала */}
                            {/* <div className='actions_group'>
                                <div className='btn btn_with_icon'>
                                    <InexactIcon />
                                </div>
                                <div className='clean btn btn_with_icon'>
                                    <CleanIcon />
                                </div>
                                </div> */}
                            {savingTimestamp > 0 && (
                                <span className='transcript-control__saving-date'>
                                    {isSaved
                                        ? `Сохранено ${getDateFromTimestamp(savingTimestamp)}
                                    в ${getTimeFromTimestamp(savingTimestamp)}`
                                        : 'Сохранение...'}
                                </span>
                            )}
                            <div style={{ flex: 1 }}></div>
                            <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'brief' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('brief'));
                                }}
                            >
                                <ProtocolIcon />
                                <span>Brief</span>
                            </div>
                            <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'topics' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('topics'));
                                }}
                            >
                                <TaskIcon />
                                <span>Topics</span>
                            </div>
                            <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'tasks' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('tasks'));
                                }}
                            >
                                <QuestionIcon />
                                <span>Tasks</span>
                            </div>
                            <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'highlights' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('highlights'));
                                }}
                            >
                                <HighlightIcon />
                                <span>Highlights</span>
                            </div>
                            <div
                                className={`transcript-control__protocol btn btn_with_icon ${
                                    demoView === 'projects' ? 'btn_secondary' : ''
                                }`}
                                onClick={() => {
                                    if (!isEditingAllowed) {
                                        return;
                                    }
                                    dispatch(changeDemoView('projects'));
                                }}
                            >
                                <ProjectsIcon />
                                <span>Projects</span>
                            </div>
                        </div>
                        <div className='transcript-control__up' onClick={scrollToTop}>
                            <ArrowIcon />
                        </div>
                    </div>
                </div>
            )}
            {shareModalIsOpen && (
                <SimpleTranscriptShare
                    transcriptId={transcript.session_id}
                    fileId={transcript.file_id}
                    hideModal={() => setShareModalIsOpen(false)}
                    savedShareToken={shareToken}
                    setSavedShareToken={(token) => setShareToken(token)}
                />
            )}
        </>
    );
};

export default NewTranscriptControl;
